/* eslint guard-for-in:0 */
import { GERMAN_DATE_TIME_SHORT_DAY_FNS } from '../constants/dateFormats'
import memoize from 'lodash/memoize'
import { format, isSameDay, isBefore, isEqual, isAfter } from 'date-fns'
import { default as de } from 'date-fns/locale/de'

export function getDateBefore(mDate, dates) {
  const datesBefore = dates.filter((date) => isEqual(date.beginn, mDate) || isBefore(date.beginn, mDate))
  return datesBefore.length ? getLatestDate(datesBefore) : false
}

export function getDateAfter(mDate, dates) {
  const datesAfter = dates.filter((date) => isEqual(date.beginn, mDate) || isAfter(date.beginn, mDate))
  return datesAfter.length ? getEarliestDate(datesAfter) : false
}

export function getEarliestDate(dates) {
  return dates.reduce((previous, current) => {
    return isBefore(current.beginn, previous.beginn) ? current : previous
  })
}

export function getLatestDate(dates) {
  return dates.reduce((previous, current) => {
    return isAfter(current.beginn, previous.beginn) ? current : previous
  })
}

export function getDateBeforeSameDaySameTherapeut(date, dates) {
  const filtered = dates.filter(
    (dt) => isSameDay(dt.beginn, date.beginn) && dt.therapeut === date.therapeut && dt.id !== date.id,
  )
  return filtered.length ? getDateBefore(date.beginn, filtered) : false
}

export const getDateBeforeSameDaySameTherapeutCached = memoize(
  getDateBeforeSameDaySameTherapeut,
  function calcParamHash(object) {
    let hash = ''
    for (const key in object) {
      // noinspection JSUnfilteredForInLoop
      hash += object[key]
    }
    return hash
  },
)

export function getDateAfterSameDaySameTherapeut(date, dates) {
  const filtered = dates.filter(
    (dt) =>
      isSameDay(dt.beginn, date.beginn) &&
      dt.therapeut === date.therapeut &&
      dt.id !== date.id &&
      dt.absagegrund === null,
  )
  return filtered.length ? getDateAfter(date.ende, filtered) : false
}

export const getDateAfterSameDaySameTherapeutCached = memoize(
  getDateAfterSameDaySameTherapeut,
  function calcParamHash(object) {
    let hash = ''
    for (const key in object) {
      // noinspection JSUnfilteredForInLoop
      hash += object[key]
    }
    return hash
  },
)

export function terminIstAbgesagt({ absagegrund }) {
  return typeof absagegrund === 'string' && absagegrund !== 'ohne Absage nicht erschienen'
}

export function dateIsCancelledOrNoShow({ absagegrund }) {
  return typeof absagegrund === 'string' && absagegrund !== ''
}

export function abgesagteTermineEntfernen(termine) {
  if (!Array.isArray(termine)) {
    if (__DEV__) console.warn('abgesagteTermineEntfernen() got called with an object as argument instead of an array!')
    return termine
  }
  return termine.filter((t) => !!t?.id).filter((termin) => !terminIstAbgesagt(termin))
}

export function removeCancelledDatesAndNoShows(dates) {
  if (!Array.isArray(dates)) {
    if (__DEV__) console.warn('abgesagteTermineEntfernen() got called with an object as argument instead of an array!')
    return dates
  }
  return dates.filter((date) => !!date?.id).filter((date) => !dateIsCancelledOrNoShow(date))
}

export function sortByBeginnAscending(a, b) {
  if (isBefore(a.beginn, b.beginn)) {
    return -1
  }
  if (isBefore(b.beginn, a.beginn)) {
    return 1
  }
  return 0
}

export function sortByBeginnDescending(a, b) {
  if (isAfter(a.beginn, b.beginn)) {
    return -1
  }
  if (isAfter(b.beginn, a.beginn)) {
    return 1
  }
  return 0
}

export function buildHVOTerminCountString(hvo, termine, terminId, leistungenKK) {
  if (!hvo || !hvo.termine || !termine || !terminId || hvo.nagelkorrektur) return ''
  const hvoTermine = hvo.termine.map((terminId) => termine[terminId])
  console.log('hvoTermine: ', hvoTermine)
  const nichtAbgesagteTermine = hvoTermine?.length ? removeCancelledDatesAndNoShows(hvoTermine) : []
  const clonedTermine = nichtAbgesagteTermine.slice().sort(sortByBeginnAscending)

  return ` (HVO-Termin Nr. ${clonedTermine.findIndex((termin) => termin.id === terminId) + 1})`
}

export function buildLeistungenString(leistungen, leistungenKK, leistungenPrivat) {
  return Array.isArray(leistungen)
    ? leistungen
        .map(({ istKKLeistung, positionsnummer, id }) =>
          istKKLeistung
            ? (leistungenKK[positionsnummer] && leistungenKK[positionsnummer].kuerzel) ??
              leistungenKK[positionsnummer].beschreibung
            : leistungenPrivat[id] && leistungenPrivat[id].kuerzel,
        )
        .filter((kuerzel) => !!kuerzel)
        .join(', ')
    : ''
}

export function buildTerminString(termin, leistungenKK, leistungenPrivat) {
  return (
    format(termin.beginn, GERMAN_DATE_TIME_SHORT_DAY_FNS, { locale: de }) +
    (termin.leistungen && termin.leistungen.length > 0
      ? ' | ' + buildLeistungenString(termin.leistungen, leistungenKK, leistungenPrivat)
      : '') +
    (termin.hausbesuch ? ' | \u2302' : '')
  )
}

export function checkBedarfVorbereitung(leistungen, leistungenKK, leistungenPrivat) {
  return Array.isArray(leistungen)
    ? leistungen
        .map(({ istKKLeistung, positionsnummer, id }) =>
          istKKLeistung
            ? leistungenKK[positionsnummer] && leistungenKK[positionsnummer].vorbereitungsbedarf
            : leistungenPrivat[id] && leistungenPrivat[id].vorbereitungsbedarf,
        )
        .filter((vorbereitungsbedarf) => !!vorbereitungsbedarf)
        .includes(true)
    : false
}

export function buildVorbereitungenString(leistungen, leistungenKK, leistungenPrivat) {
  return Array.isArray(leistungen)
    ? leistungen
        .map(({ istKKLeistung, positionsnummer, id }) =>
          istKKLeistung
            ? leistungenKK[positionsnummer] && leistungenKK[positionsnummer].vorbereitungsnotiz
            : leistungenPrivat[id] && leistungenPrivat[id].vorbereitungsnotiz,
        )
        .filter((vorbereitungsnotiz) => !!vorbereitungsnotiz)
        .join(', ')
    : ''
}
